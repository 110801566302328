import { __assign, __awaiter, __generator } from "tslib";
import { HttpRequest } from "@aws-sdk/protocol-http";
export var useRegionalEndpointMiddleware = function (config) {
    return function (next) {
        return function (args) { return __awaiter(void 0, void 0, void 0, function () {
            var request, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        request = args.request;
                        if (!HttpRequest.isInstance(request) || config.isCustomEndpoint)
                            return [2, next(__assign({}, args))];
                        if (!(request.hostname === "s3.amazonaws.com")) return [3, 1];
                        request.hostname = "s3.us-east-1.amazonaws.com";
                        return [3, 3];
                    case 1:
                        _a = "aws-global";
                        return [4, config.region()];
                    case 2:
                        if (_a === (_b.sent())) {
                            request.hostname = "s3.amazonaws.com";
                        }
                        _b.label = 3;
                    case 3: return [2, next(__assign({}, args))];
                }
            });
        }); };
    };
};
export var useRegionalEndpointMiddlewareOptions = {
    step: "build",
    tags: ["USE_REGIONAL_ENDPOINT", "S3"],
    name: "useRegionalEndpointMiddleware",
    override: true,
};
export var getUseRegionalEndpointPlugin = function (config) { return ({
    applyToStack: function (clientStack) {
        clientStack.add(useRegionalEndpointMiddleware(config), useRegionalEndpointMiddlewareOptions);
    },
}); };
